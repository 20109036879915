import { Route, Switch } from "react-router-dom";
import React from "react";
import Home from "../Home";
import AboutUs from "../Pages/AboutUs";
import ReachUs from "../Pages/ReachUs";
import Careers from "../Pages/Careers";

import ScrollIntoView from "../../components/ScrollIntoView";

// services
import StrategyServices from "../Pages/Services/StrategyServices";
import DataScience from "../Pages/Services/DataScience";
import DataEngineering from "../Pages/Services/DataEngineering";
import AnalyticServices from "../Pages/Services/AnalyticServices";
import MachineLearning from "../Pages/Services/MachineLearning";

// We serve
import BusinessSolutions from "../Pages/WeServe/BusinessSolutions";
import Healthcare from "../Pages/WeServe/Healthcare";
import SoftwareTechnology from "../Pages/WeServe/SoftwareTechnology";

//Specialization
import Bigdata from "../Pages/Specialization/Bigdata";
import SoftwareDevelopment from "../Pages/Specialization/SoftwareDevelopment";

export function Router() {
  return (
    <ScrollIntoView>
      <Switch>
        <Route exact path={"/"} component={Home} />
        <Route path="/home" component={Home} />
        <Route path="/AboutUs" component={AboutUs} />
        <Route path="/ReachUs" component={ReachUs} />
        <Route path="/Careers" component={Careers} />

        <Route path="/StrategyServices" component={StrategyServices} />
        <Route path="/DataEngineering" component={DataEngineering} />
        <Route path="/DataScience" component={DataScience} />
        <Route path="/AnalyticServices" component={AnalyticServices} />
        <Route path="/MachineLearning" component={MachineLearning} />

        <Route path="/BusinessSolutions" component={BusinessSolutions} />
        <Route path="/Healthcare" component={Healthcare} />
        <Route path="/SoftwareTechnology" component={SoftwareTechnology} />

        <Route path="/Bigdata" component={Bigdata} />
        <Route path="/SoftwareDevelopment" component={SoftwareDevelopment} />
      </Switch>
    </ScrollIntoView>
  );
}

export default Router;
