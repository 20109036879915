import React from "react";
import { Link } from "react-router-dom";

export default function AboutUs() {
  const aboutusBannerGif = require("../../assets/images/aboutus-video.mp4");

  const Img14 = require("../../assets/images/14.png");
  const Img15 = require("../../assets/images/15.png");
  const Img16 = require("../../assets/images/16.png");

  return (
    <div className="site-wrapper-reveal">
      {/*===========  feature-large-images-wrapper  Start =============*/}
      <div className="feature-large-images-wrapper section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_60">
                <h3 className="heading">
                  We provide a comprehensive range of IT services <br /> that
                  guarantee{" "}
                  <span className="text-color-primary"> your success </span>
                </h3>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="cybersecurity-about-box section-space--pb_70">
            <div className="row">
              {/* <div className="col-lg-4 offset-lg-1">
                <div className="modern-number-01">
                  <h2 className="heading  me-5">
                    <span className="mark-text">5+</span>Years’ Experience in IT
                  </h2>
                  <h6 className="heading mt-30">
                    More About Our Success Stories
                  </h6>
                </div>
              </div> */}
              <div className="col-lg-12 offset-lg-1">
                <div className="cybersecurity-about-text">
                  <div className="text">
                    Golden Software Solution offers comprehensive IT consulting
                    and services specializing in big data, data science, and
                    machine learning. We prioritize innovation, efficiency, and
                    customer satisfaction to deliver high-quality solutions that
                    meet our clients' unique needs. Our experienced team
                    provides customized services, builds lasting relationships,
                    and delivers exceptional value.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="feature-images__six">
                <div className="row">
                  <div className="col-lg-4 col-md-6 wow move-up">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-06">
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <div className="default-image">
                            <img className="img-fulid " src={Img14} alt="" />
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="heading">
                            What services do we offer?
                          </h5>
                          <div className="text">
                            We prioritize understanding your business needs to
                            provide customized solutions that precisely meet
                            your requirements.
                          </div>
                          <a href="#" className="box-images-arrow">
                            <span className="button-text">Dive in</span>
                            <i className="fas fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 wow move-up">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-06">
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <div className="default-image">
                            <img className="img-fulid" src={Img15} alt="" />
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="heading">
                            Would you like to partner with us?
                          </h5>
                          <div className="text">
                            Our proactive approach ensures that you stay ahead
                            of the curve by mitigating potential risks in data
                            management.
                          </div>
                          <a href="#" className="box-images-arrow">
                            <span className="button-text">Dive in</span>
                            <i className="fas fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 wow move-up">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-06">
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <div className="default-image">
                            <img className="img-fulid" src={Img16} alt="" />
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="heading">Can we be of assistance?</h5>
                          <div className="text">
                            Our dedicated support team is at your service round
                            the clock, 5 days a week, to promptly address any
                            issues that arise.
                          </div>
                          <a href="#" className="box-images-arrow">
                            <span className="button-text">Dive in</span>
                            <i className="fas fa-arrow-right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                </div>
              </div>
              <div className="section-under-heading text-center section-space--mt_60">
                Every challenge presents a unique opportunity for growth and
                development. <br />
                <a href="#">
                  Embrace challenges and turn them into opportunities for
                  success!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*========= About Delivering Wrapper Start ===========*/}
      <div className="about-delivering-wrapper section-space--ptb_100 bg-gray-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-wrap text-center section-space--mb_20">
                <h3 className="heading">
                  We specialize in providing <br />
                  optimal solutions.
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="delivering-optimal-wrap">
                <div className="list-item">
                  <div className="marker" />
                  <div className="title-wrap">
                    <h4 className="title"> IT-based Warranty Management </h4>
                    <div className="desc">
                      Our services enhance the customer experience by providing
                      secure&amp; functional end-to-end warranty management
                      solutions.{" "}
                    </div>
                  </div>
                </div>
                <div className="list-item">
                  <div className="marker" />
                  <div className="title-wrap">
                    <h4 className="title"> Reliable Quality Control</h4>
                    <div className="desc">
                      Our quality control system is not just a responsibility,
                      but a guarantee to gain customer trust in our highly
                      reliable services.{" "}
                    </div>
                  </div>
                </div>
                <div className="list-item">
                  <div className="marker" />
                  <div className="title-wrap">
                    <h4 className="title">Expert and Trained Professionals</h4>
                    <div className="desc">
                      Our staff members are highly skilled and trained in IT
                      technology and technical fields to deliver exceptional
                      services.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="delivering-optimal-wrap">
                <div className="list-item">
                  <div className="marker" />
                  <div className="title-wrap">
                    <h4 className="title">
                      Product Engineering &amp; Improvement{" "}
                    </h4>
                    <div className="desc">
                      We regularly test, repair, and refine every product
                      version to develop and propose product improvements for
                      better customer satisfaction.{" "}
                    </div>
                  </div>
                </div>
                <div className="list-item">
                  <div className="marker" />
                  <div className="title-wrap">
                    <h4 className="title">
                      Integrated Infrastructure Solutions{" "}
                    </h4>
                    <div className="desc">
                      At Golden Software Solution, we provide a holistic
                      approach to core modernization with integrated
                      infrastructure solutions for a better technological
                      evolution.{" "}
                    </div>
                  </div>
                </div>
                <div className="list-item">
                  <div className="marker" />
                  <div className="title-wrap">
                    <h4 className="title">Information Security Assurance</h4>
                    <div className="desc">
                      With increasing concerns about information security, we
                      offer reliable solutions to prevent data breaches and
                      protect sensitive information.{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
