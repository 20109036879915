import React from "react";
import { CareersContainerStyles } from "../styles";
import { Link } from "react-router-dom";

export default function Careers() {
  const GSS = require("../../assets/images/gss.png");

  const careersData = [
    {
      id: 1,
      title: "Data Engineer",
      description:
        "We are seeking a talented Data Engineer to join our team. As a Data Engineer, you will play a crucial role in developing high-quality software solutions that meet user requirements and technical specifications. Collaborate with cross-functional teams to design, develop, and test software applications throughout the entire software development lifecycle. Your expertise in writing clean, efficient, and maintainable code using industry best practices will be invaluable. Troubleshoot and debug software defects, provide constructive feedback, and stay up-to-date with emerging technologies and industry trends. Join us and be part of a team that continuously improves software development processes to deliver outstanding results.",
      posted_date: "03-07-2023",
      location: "South Portland",
      work_type: "Remote",
    },
    {
      id: 2,
      title: "SAP Consultant",
      description:
        "Join our team as a SAP Consultant and contribute to the success of our company's SAP projects. As a SAP Consultant, you will work closely with clients to understand their business requirements and provide innovative solutions using your expertise in SAP modules. Collaborate with cross-functional teams, analyze business processes, and configure SAP systems to meet client needs. Utilize your strong problem-solving skills and in-depth knowledge of SAP functionalities to deliver high-quality results. Take this opportunity to grow your career and make a significant impact in the field of SAP consulting",
      posted_date: "03-07-2023",
      location: "South Portland",
      work_type: "Remote",
    },
    {
      id: 2,
      title: "RPA Developer",
      description:
        "Join our team as an RPA Developer and be at the forefront of automation technology. As an RPA Developer, you will play a key role in designing, developing, and implementing robotic process automation solutions for our clients. Collaborate with cross-functional teams to understand business processes, identify automation opportunities, and develop efficient RPA workflows. Utilize your expertise in RPA tools and technologies to create bots that streamline processes and improve productivity. Troubleshoot and debug automation issues, provide technical guidance, and stay updated with the latest advancements in RPA. Join us in transforming businesses through the power of automation and drive innovation in the field of RPA.",
      posted_date: "03-07-2023",
      location: "South Portland",
      work_type: "Remote",
    },
  ];

  return (
    <div className="container">
      <CareersContainerStyles>
        <div className="item--sub-title">Careers </div>
        <h3>
          <b>
            Golden Software Solution provides the greatest internships and
            positions, from startups to Fortune 500 companies.
          </b>
        </h3>
        {careersData.map((item: any) => {
          return (
            <div className="grid">
              <div className="d-flex">
                <img src={GSS} alt="" />
                <div className="d-flex flex-column">
                  <h5>{item.title}</h5>
                  <p>
                    <i className="fa fa-paperclip"></i>Posted date :{" "}
                    {item.posted_date}
                  </p>

                  <p>
                    <i className="fa fa-map"></i>
                    {item.location}
                  </p>
                  <p>
                    <i className="fa fa-life-ring"></i>
                    {item.work_type}
                  </p>
                </div>
              </div>
              <div>
                <p className="description">{item.description}</p>
              </div>
              <div>
                <Link to="/ReachUs">
                  <button className="">Apply now</button>
                </Link>
              </div>
            </div>
          );
        })}
      </CareersContainerStyles>
    </div>
  );
}
