import React from "react";
import { Link } from "react-router-dom";
import { Banner } from "./styles";
import ClientList from "../GenericComponents/ClientList";
import Lottie from "lottie-react";

export default function Home() {
  const CloudComputing = require("../../assets/lottie/cloud-computing.json");
  const Lottie1 = require("../../assets/lottie/Technology.json");

  const Image1 = require("../../assets/images/1.png");
  const Image2 = require("../../assets/images/2.png");
  const Image3 = require("../../assets/images/3.png");

  const Image4 = require("../../assets/images/4.png");
  const Image5 = require("../../assets/images/5.png");
  const Image6 = require("../../assets/images/6.png");

  const Image7 = require("../../assets/images/7.png");
  const Image8 = require("../../assets/images/8.png");

  const aboutusBannerGif = require("../../assets/images/about-banner.svg");

  const S1 = require("../../assets/images/svg/linea-basic-heart.svg");
  const S2 = require("../../assets/images/svg/linea-basic-case.svg");
  const S3 = require("../../assets/images/svg/linea-basic-alarm.svg");
  const S4 = require("../../assets/images/svg/linea-basic-geolocalize-05.svg");
  const S5 = require("../../assets/images/svg/linea-ecommerce-money.svg");
  const S6 = require("../../assets/images/svg/linea-basic-spread-text-bookmark.svg");
  const S7 = require("../../assets/images/svg/linea-music-headphones.svg");
  const S8 = require("../../assets/images/svg/linea-basic-gear.svg");
  const Img26 = require("../../assets/images/26.png");

  const solutionsData = [
    {
      title: "Application Design",
      description:
        "We provide end-to-end application design services, including requirements gathering, system architecture, UI design, and development.",
      src: S1,
    },
    {
      title: "Technology Management",
      description:
        " We Oversight technology resources and services in an organization. Includes governance, project management, and resource management.",
      src: S2,
    },
    {
      title: "Information Security",
      description:
        "We Protect data from unauthorized access, use, disclosure, or destruction. Includes cybersecurity, data protection, privacy, IT security, and network security.",
      src: S3,
    },
    {
      title: "Business Innovation",
      description:
        "We Process developing and implementing new ideas and strategies to improve business performance.Includes process improvement, and optimization.",
      src: S4,
    },
    {
      title: "IT Infrastructure Management",
      description:
        "We Manage IT and services in an organization. Includes planning, design, deployment, and optimization of hardware, software, and networks.",
      src: S5,
    },
    {
      title: " Application Firewall",
      description:
        " We offer advanced application firewall services, including network firewall,  intrusion prevention, and detection systems.",
      src: S6,
    },
    {
      title: "Desktop Virtualization",
      description:
        "We provide desktop virtualization solutions to improve workforce flexibility, efficiency, and security by enabling remote work.",
      src: S7,
    },
    {
      title: " Software Development",
      description:
        "We provide end-to-end software development services, including programming, software design, project management, and quality assurance.",
      src: S8,
    },
  ];

  return (
    <div>
      <div id="main-wrapper">
        <div className="site-wrapper-reveal">
          {/* <section
            className="contact-us-section-wrappaer machine-learning-contact-us-bg section-space--ptb_120 animate__animated animate__fadeIn"
            style={{
              backgroundImage: `url(${Img26})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              backgroundColor: "rgb(6 10 52)",
            }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-6">
                  <div className="conact-us-wrap-three animate__animated animate__fadeInLeft">
                    <h6 className="mb-3 section-sub-title">
                      Empowering Business Growth through Advanced Technology
                      Solutions
                    </h6>
                    <h3 className="heading text-white">
                      We strive to keep pace with the latest advancements,
                      ensuring that our solutions are up-to-date and in line
                      with industry standards.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section> */}

          <section className="bg-black section-space--ptb_50 pt-5 pt-sm-0 animate__animated animate__fadeIn">
            <div className="our-experience-wrapper">
              <div className="container">
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-12 col-sm-6">
                    <h3 className="heading text-white">
                      EMPOWERING BUSINESS GROWTH THROUGH ADVANCED TECHNOLOGY
                      SOLUTIONS
                    </h3>
                    <h4 className="heading text-white ">
                      <span className="text-color-primary mt-30">
                        {" "}
                        We strive to keep pace with the latest advancements,
                        ensuring that our solutions are up-to-date and in line
                        with industry standards.
                      </span>
                    </h4>
                    <div className="section-title small-mb__40 tablet-mb__40 ">
                      <div className="sider-title-button-box mt-30">
                        <Link to="/ReachUs" className="ht-btn ht-btn-md">
                          Join our team
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-5 offset-sm-1">
                    <Lottie animationData={Lottie1} loop={true} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section>
            <ClientList />
          </section> */}

          <section className="site-wrapper-reveal animate__animated animate__fadeInUp">
            <div className="feature-icon-wrapper section-space--ptb_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_40">
                      <h3 className="heading">
                        Golden Software Solution Provides exceptional IT
                        solutions
                        <br />
                        <span className="text-color-primary text-uppercase">
                          {" "}
                          to ensure your success.
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="feature-list__one">
                      <div className="row">
                        {solutionsData.map((item: any) => {
                          return (
                            <div className="col-lg-3 col-md-6">
                              <div className="ht-box-icon style-01 single-svg-icon-box">
                                <div className="icon-box-wrap">
                                  <div className="icon">
                                    <img src={item.src} alt="" />
                                  </div>

                                  <div className="content">
                                    <h5 className="heading">{item.title}</h5>
                                    <div
                                      className="text"
                                      title={item.description}
                                    >
                                      {item.description}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="section-space--ptb_80 pb-0 bg-gradiant-1">
            <div className="our-experience-wrapper">
              <div className="container">
                <div className="row ">
                  <div className="col-12 d-flex justify-content-center">
                    {" "}
                    <h3 className="heading text-white text-center">
                      Unlocking Digital Potential:
                      <br />
                      <span className="text-color-primary">
                        {" "}
                        Comprehensive Digital Transformation Services by Golden
                        Software Solution
                      </span>
                    </h3>
                  </div>
                </div>
                <div className="row d-flex justify-content-center align-items-center">
                  <div className="col-12 col-sm-6">
                    <div className="section-title small-mb__40 tablet-mb__40">
                      <p className="text-white mt-20">
                        Golden Software Solution specializes in the end-to-end
                        Digital Transformation service stack, covering Design,
                        Product-Centric Engineering, and cloud-based
                        infrastructure.
                      </p>

                      <p className="text-white mt-20">
                        We enable our partners to play impactful roles across
                        diverse industry domains, and technology stacks. With a
                        keen eye on industry trends and solutions, we have
                        identified four key practice areas that drive
                        significant digital impact for our customers.
                      </p>
                      <div className="sider-title-button-box mt-30">
                        <Link to="/ReachUs" className="ht-btn ht-btn-md">
                          Join our team
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-5 offset-sm-1">
                    <Lottie animationData={CloudComputing} loop={true} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-section">
            <div className="feature-large-images-wrapper section-space--pt_100">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="section-title-wrap text-center section-space--mb_40">
                      <h3 className="heading">
                        Experience the expertise of our team
                        <br />
                        <span className="text-color-primary text-uppercase">
                          {" "}
                          What makes us stand out in the market?
                        </span>
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="row row--35">
                      <div className="col-lg-4 col-md-6 mt-30">
                        {/* Box large image warap Start */}
                        <a
                          href="/"
                          className="box-large-image__wrap wow move-up"
                        >
                          <div className="box-large-image__box">
                            <div className="box-large-image__midea">
                              <div className="images-midea">
                                <img
                                  src={Image4}
                                  width={330}
                                  height={330}
                                  className="img-fluid"
                                  alt=""
                                />
                                <div className="button-wrapper">
                                  <div className="btn tm-button">
                                    <span className="button-text">
                                      {" "}
                                      Read on{" "}
                                    </span>
                                  </div>
                                </div>
                                <div className="heading-wrap">
                                  <h5 className="heading">
                                    Our Team: Experts in IT Solutions
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="box-large-image__content mt-30 text-center">
                              <p>
                                Our experienced team offers cutting-edge{" "}
                                <strong>IT solutions</strong> to optimize
                                performance and streamline operations for your
                                business.
                              </p>
                            </div>
                          </div>
                        </a>
                        {/* Box large image warap End */}
                      </div>
                      <div className="col-lg-4 col-md-6  mt-30">
                        {/* Box large image warap Start */}
                        <a
                          href="/"
                          className="box-large-image__wrap wow move-up"
                        >
                          <div className="box-large-image__box">
                            <div className="box-large-image__midea">
                              <div className="images-midea">
                                <img
                                  src={Image5}
                                  width={330}
                                  height={330}
                                  className="img-fluid"
                                  alt=""
                                />
                                <div className="button-wrapper">
                                  <div className="btn tm-button">
                                    <span className="button-text">
                                      {" "}
                                      Read on{" "}
                                    </span>
                                  </div>
                                </div>
                                <div className="heading-wrap">
                                  <h5 className="heading">
                                    Why Choose Us: Our Market Advantage{" "}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="box-large-image__content mt-30 text-center">
                              <p>
                                We stand out in the market due to our{" "}
                                <strong>
                                  {" "}
                                  innovative approach, efficient services,
                                </strong>{" "}
                                and dedication to customer satisfaction.
                              </p>
                            </div>
                          </div>
                        </a>
                        {/* Box large image warap End */}
                      </div>
                      <div className="col-lg-4 col-md-6  mt-30">
                        {/* Box large image warap Start */}
                        <a
                          href="/"
                          className="box-large-image__wrap wow move-up"
                        >
                          <div className="box-large-image__box">
                            <div className="box-large-image__midea">
                              <div className="images-midea">
                                <img
                                  src={Image6}
                                  width={330}
                                  height={330}
                                  className="img-fluid"
                                  alt=""
                                />
                                <div className="button-wrapper">
                                  <div className="btn tm-button">
                                    <span className="button-text">
                                      {" "}
                                      Read on{" "}
                                    </span>
                                  </div>
                                </div>
                                <div className="heading-wrap">
                                  <h5 className="heading">
                                    Experience Matters: Trust Our Expertise
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="box-large-image__content mt-40 text-center">
                              <p>
                                With years of experience in IT and consulting,{" "}
                                <strong>Golden Software Solution </strong> is
                                the trusted partner for businesses seeking
                                long-term success.
                              </p>
                            </div>
                          </div>
                        </a>
                        {/* Box large image warap End */}
                      </div>
                    </div>
                    <div className="section-under-heading text-center section-space--mt_40">
                      <a href="/">
                        Read further to learn about our approach{" "}
                        <i className="ml-1 button-icon fas fa-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="blog-section-wrapper section-space--pt_100  section-space--pb_70">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 col-md-12 wow move-up">
                  <div className="section-title tablet-mb__60 section-space--mb_30 tablet-mt__0 small-mt__0 small-mb__60 mt-30">
                    {/* <h6 className="section-sub-title mb-20">
                      Blogs &amp; news
                    </h6> */}
                    <h3 className="heading">
                      Golden Software Solution specializes in the end-to-end
                      Digital Transformation service stack.
                    </h3>
                    <ul className="infotechno-blog-list mt-30">
                      <li>
                        <a href="/">
                          With an emphasis on creativity and originality
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          We focus on innovation to deliver cutting-edge Big
                          Data services to our clients.
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          Our high-quality Big Data solutions help clients
                          achieve their goals and stay competitive.
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          Our commitment to customer satisfaction ensures that
                          we provide top-quality Big Data services to meet
                          client needs.
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  {/*======= Single Blog Item Start ========*/}
                  <div className="single-blog-item blog-grid">
                    {/* Post Feature Start */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src={Image7}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* Post Feature End */}
                    {/* Post info Start */}
                    <div className="post-info lg-blog-post-info">
                      <h5 className="post-title font-weight--bold">
                        <a href="/">Our Mission</a>
                      </h5>
                      <div className="post-excerpt mt-15">
                        <p>
                          To drive innovation in the digital landscape and
                          deliver exceptional customer experiences by fostering
                          strategic partnerships and seamless integrations for
                          our valued clients.
                        </p>
                      </div>
                    </div>
                    {/* Post info End */}
                  </div>
                  {/*===== Single Blog Item End =========*/}
                </div>
                <div className="col-lg-4 col-md-6 wow move-up">
                  {/*======= Single Blog Item Start ========*/}
                  <div className="single-blog-item blog-grid">
                    {/* Post Feature Start */}
                    <div className="post-feature blog-thumbnail">
                      <a href="blog-post-layout-one.html">
                        <img
                          className="img-fluid"
                          src={Image8}
                          alt="Blog Images"
                        />
                      </a>
                    </div>
                    {/* Post Feature End */}
                    {/* Post info Start */}
                    <div className="post-info lg-blog-post-info">
                      <h5 className="post-title font-weight--bold">
                        <a href="/">Our Vision & Our Purpose</a>
                      </h5>
                      <div className="post-excerpt mt-15">
                        <p>
                          To be the preferred digital transformation partner for
                          leading enterprises across industries. To leverage
                          disruptions as opportunities for our clients' growth
                          and success.
                        </p>
                      </div>
                      {/* <div className="btn-text">
                        <a href="/">
                          Read more{" "}
                          <i className="ml-1 button-icon fas fa-arrow-right" />
                        </a>
                      </div> */}
                    </div>
                    {/* Post info End */}
                  </div>
                  {/*===== Single Blog Item End =========*/}
                </div>
              </div>
            </div>
          </section>

          <section className="contact-us-section-wrappaer infotechno-contact-us-bg section-space--ptb_120">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-lg-6">
                  <div className="conact-us-wrap-one">
                    <h3 className="heading">
                      Get in touch with our knowledgeable{" "}
                      <span className="text-color-primary"> IT team </span> for
                      more information.{" "}
                    </h3>
                    {/* <div className="sub-heading">
                      We’re available for 8 hours a day!
                      <br />
                      Contact to require a detailed analysis and assessment of
                      your plan.
                    </div> */}
                  </div>
                </div>
                <div className="col-lg-6 col-lg-6">
                  <div className="contact-info-one text-center">
                    <div className="icon">
                      <span className="fas fa-phone" />
                    </div>
                    <h6 className="heading font-weight--reguler">
                      Reach out now!
                    </h6>
                    <h2 className="call-us">
                      <a href="tel:190068668">+1(469)213-1949</a>
                    </h2>
                    <div className="mt-20">
                      <a className="btn btn--gradient">Get in touch</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}
