import React from "react";
import { Link } from "react-router-dom";
import { GridWhite } from "../../styles";

export default function AnalyticServices() {
  const Img12 = require("../../../assets/images/12.png");

  const data = [
    {
      title: "Business analytics",
      description:
        "It informs confident data-driven decisions through real-time insights, forecasts, and data analytics to drive business outcomes.",
      linkTo: "/Bigdata",
    },
    {
      title: "Text analytics",
      description:
        "streamlines processes and reduces time spent on cumbersome tasks, empowering business experts to create value for stakeholders.",
      linkTo: "/",
    },
    {
      title: "Data science analytics",
      description:
        "AI is critical for organizations to innovate, reduce risk and fraud, and drive revenue growth with supported analytic tools.",
      linkTo: "/",
    },
    // {
    //   title: "IT Design",
    //   description:
    //     "We provide the most responsive and functional IT design for companies and businesses worldwide.",
    //   linkTo: "/",
    // },
    // {
    //   title: "IT Design",
    //   description:
    //     "We provide the most responsive and functional IT design for companies and businesses worldwide.",
    //   linkTo: "/",
    // },
    // {
    //   title: "IT Design",
    //   description:
    //     "We provide the most responsive and functional IT design for companies and businesses worldwide.",
    //   linkTo: "/",
    // },
  ];
  return (
    <div>
      <div>
        {/*===========  feature-icon-wrapper  Start =============*/}
        <div className="feature-icon-wrapper bg-gray section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  <h6 className="section-sub-title mb-20">
                    Industries we Serve
                  </h6>
                  <h3 className="heading">
                    Services We Deliver
                    <br /> we provide{" "}
                    <span className="text-color-primary">
                      {" "}
                      truly prominent IT solutions.
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="feature-list__two">
                  <div className="row">
                    {data.map((item: any) => {
                      return (
                        <div className="col-lg-4 col-md-6 wow move-up mb-5">
                          <GridWhite className="ht-box-icon style-02 single-svg-icon-box">
                            <div className="icon-box-wrap">
                              <div className="icon">
                                <div
                                  className="svg-icon"
                                  id="svg-1"
                                  data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                />
                              </div>
                              <div className="content">
                                <h5 className="heading">{item.title}</h5>
                                <div className="text">{item.description}</div>
                                <div className="feature-btn">
                                  <Link to={item.linkTo}>
                                    <span className="button-text">Dive in</span>
                                    <i className="fas fa-arrow-right ms-3" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </GridWhite>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="feature-list-button-box mt-30 text-center">
                  <a href="#" className="ht-btn ht-btn-md">
                    Talk to a consultant
                  </a>
                  <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                    Contact us now{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===========  feature-icon-wrapper  End =============*/}

        {/*=========== Service Projects Wrapper Start =============*/}
        <div className="service-projects-wrapper section-space--pt_100 mb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  {/* <h6 className="section-sub-title mb-20">Case studies</h6> */}
                  <h3 className="heading">
                    Impact of data analytics on{" "}
                    <span className="text-color-primary">business</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="row">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Enable data accessibility through data fabric
                          </p>
                        </h3>
                        <div className="text">
                          Learn how this emerging approach to data strategy and
                          architecture streamlines data access for self-service
                          while maintaining data governance and privacy.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img12} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img12} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Enhance agility through data administration
                          </p>
                        </h3>
                        <div className="text">
                          Boost cost efficiency and productivity with unmatched
                          scale and robustness through comprehensive data
                          administration.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12"></div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="row">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}

                        <h3>
                          <p className="sub-text text-color-primary">
                            Forecast results with business intelligence
                          </p>
                        </h3>
                        <div className="text">
                          Provide business users with AI-powered self-service
                          business intelligence to anticipate and influence
                          future outcomes.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img12} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img12} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}

                        <h3>
                          <p className="sub-text text-color-primary">
                            Personalize interactions with customer service
                          </p>
                        </h3>
                        <div className="text">
                          Build stronger customer connections through all
                          channels by delivering more precise and succinct
                          responses to inquiries in any language.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=========== Service Projects Wrapper End =============*/}
      </div>

      {/* <HowWeWorks /> */}
    </div>
  );
}
