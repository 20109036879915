import React from "react";
import { Link } from "react-router-dom";

export default function ReachUs() {
  return (
    <div className="site-wrapper-reveal">
      {/*====================  Conact us Section Start ====================*/}
      <div className="contact-us-section-wrappaer section-space--pt_100 section-space--pb_70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-lg-6">
              <div className="conact-us-wrap-one mb-30">
                <h3 className="heading">
                  For more details, feel free to <br />
                  <span className="text-color-primary">
                    reach out to us{" "}
                  </span>{" "}
                  through our social media channels.{" "}
                </h3>
                <div className="sub-heading">
                  We strive to provide a prompt response to your requests,{" "}
                  <br /> typically within two business days or less.
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-lg-6">
              <div className="contact-form-wrap">
                {/* <form id="contact-form" action="http://whizthemes.com/mail-php/jowel/mitech/php/mail.php" method="post"> */}
                <form
                  id="contact-form"
                  action="assets/php/mail.php"
                  method="post"
                >
                  <div className="contact-form">
                    <div className="contact-input">
                      <div className="contact-inner">
                        <input
                          name="con_name"
                          type="text"
                          placeholder="Name *"
                        />
                      </div>
                      <div className="contact-inner">
                        <input
                          name="con_email"
                          type="email"
                          placeholder="Email *"
                        />
                      </div>
                    </div>
                    <div className="contact-inner">
                      <input
                        name="con_subject"
                        type="text"
                        placeholder="Subject *"
                      />
                    </div>
                    <div className="contact-inner contact-message">
                      <textarea
                        name="con_message"
                        placeholder="Please describe what you need."
                        defaultValue={""}
                      />
                    </div>
                    <div className="submit-btn mt-20">
                      <button className="ht-btn ht-btn-md" type="submit">
                        Send message
                      </button>
                      <p className="form-messege" />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  Conact us Section End  ====================*/}
      {/*====================  Conact us info Start ====================*/}
      {/* <div className="contact-us-info-wrappaer section-space--pb_100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="conact-info-wrap mt-30">
                <h3 className="heading mb-20">United States</h3>
                <ul className="conact-info__list">
                  <li>500 Southborough Drive, LL2, South Portland, ME 04106</li>
                  <li>
                    <a href="#" className="hover-style-link text-color-primary">
                      info@goldensoftsol.com
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="hover-style-link text-black font-weight--bold"
                    >
                      +1(469)213-1949
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/*====================  Conact us info End  ====================*/}
      {/*========== Call to Action Area Start ============*/}
      <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-8 col-lg-7">
              <div className="cta-content md-text-center">
                <h3 className="heading">
                  Our diverse range of IT services and placements are designed
                  to help you achieve{" "}
                  <span className="text-color-primary"> success</span>
                </h3>
              </div>
            </div>
            <div className="col-xl-4 col-lg-5">
              <div className="cta-button-group--two text-center">
                <a href="#" className="btn btn--white btn-one">
                  <span className="btn-icon me-2">
                    <i className="far fa-comment-alt" />
                  </span>{" "}
                  Let's talk
                </a>
                <a href="#" className="btn btn--secondary btn-two ">
                  <span className="btn-icon me-2">
                    <i className="fas fa-info-circle" />
                  </span>{" "}
                  Get info
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*========== Call to Action Area End ============*/}
    </div>
  );
}
