import styled from "styled-components";
// import {displayFlex, alignedCenter, Padding0y50x, flexCenter, fontBold} from '../../styles';
// import {headerBoxShadow, headerHeight, logoWidth, topBarHeight} from '../../../variables';
// import { theme } from '../../../theme';
interface CustomScrollProps {
  scrolled?: boolean;
}

export const HeaderWrapper = styled.div`
  background: white;
  /* background: linear-gradient(90deg, rgba(247,0,0,0.469625350140056) 0%, rgba(255,255,255,1) 50%, rgba(133,252,69,0.39399509803921573) 100%); */

  h2 {
    margin: 0;
    color: black;
    font-weight: bold;
    letter-spacing: 1px;
    text-decoration: none;
  }

  .logo {
    max-height: 5rem;
  }

  .menuzord {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .active-tab {
    background-color: ${(props: any) => props.theme.primary.THEME};
    color: white;
    display: flex;
    align-items: center;
  }
`;

export const SideMenu = styled.div`
  width: 70%;
  height: 100vh;
  background: #0e0e0ff7;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`;
