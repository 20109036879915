import styled, { css } from "styled-components";
import { sideBar, arrowDimentions } from "../variables";

interface Selected {
  isOpen?: boolean;
}

export const flexCenter = css`
  align-items: center;
  justify-content: center;
`;

export const alignedCenter = css`
  align-items: center;
`;

export const displayFlex = css`
  display: flex;
`;

export const cursorPointer = css`
  cursor: pointer;
`;

export const Padding0y50x = css`
  padding: 0px 50px;
`;

export const fontThin = css`
  font-weight: 200;
`;

export const fontRegular = css`
  font-weight: 400;
`;

export const fontMedium = css`
  font-weight: 500;
`;

export const fontBold = css`
  font-weight: 700;
`;

export const FontLight = styled.b`
  font-weight: 400;
  color: ${(props) => props.theme.primary.fontLight};
`;

export const FlexCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
`;

export const DisplayCenter = styled.div`
  ${flexCenter};
  ${displayFlex};
  flex: 1;
`;

export const rowSpaceBetween = styled.div`
  ${displayFlex};
  flex-flow: column;
`;

export const AppContainer = styled.div`
  width: 100%;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  height: 100%;

  .piechart-wrapper {
    border-bottom: solid;
  }
`;

export const MainContainer = styled.div`
  overflow: auto;
`;

export const AdView = styled.div`
  width: ${sideBar};
  background-color: #ccc;
  ${displayFlex};
  -webkit-flex-flow: column;
  flex-flow: column;
  background-image: linear-gradient(to right, #7073c7, #3f51b5);
`;

export const ArrowView = styled.div<Selected>`
  position: fixed;
  right: ${(props) => (props.isOpen ? "200px" : "0")};
  bottom: 0;
  background-color: ${(props) => props.theme.primary.whiteColor};
  border: solid 2px ${(props) => props.theme.primary.color};
  border-radius: 100px;
  width: ${arrowDimentions};
  height: ${arrowDimentions};
  ${displayFlex};
  ${flexCenter};
  ${cursorPointer};
  z-index: 9999;
`;

export const HomeImageView = styled.div`
  ${displayFlex};
  ${flexCenter};

  img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
`;

export const TitleHeader = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;

  @media (max-width: 767px) {
    width: 100% !important;
  }

  h5 {
    ${fontMedium};
    text-transform: capitalize;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 5px;
    color: ${(props) => props.theme.primary.SECONDARY_THEME};
  }

  .title {
    font-size: 34px;
    line-height: 44px;
    margin-bottom: 0;
    color: ${(props) => props.theme.primary.THEME};
    font-style: italic;

    span {
      ${fontBold};
      font-style: normal;
      ${fontBold};
      font-family: "Playfair Display", serif;
      letter-spacing: 1.2px;
      color: ${(props) => props.theme.primary.SECONDARY_THEME};
    }
  }
`;

export const Sticky = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 99;

  @media (max-width: 767px) {
    position: relative;
  }
`;

export const SpecialFont = styled.b`
  color: ${(props): string => props.theme.primary.SECONDARY_THEME};
  font-size: 25px;
`;

export const Share = styled.div`
  position: fixed;
  right: 0;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  box-shadow: 0 0 10px #eee;
  background-color: white;
  padding: 1rem;
`;

export const GridWhite = styled.div`
  background-color: white;
  border-radius: 10px;
`;

export const CareersContainerStyles = styled.div`
  padding: 20px 0;
  position: relative;
  margin-top: 30px;

  h3 {
    margin: 0;
    font-size: 36px;
    line-height: 1.33333;
    color: #0d2252;
    font-weight: 900;
    padding: 20px 0;
    font-family: "Roboto", Sans-serif !important;

    @media (max-width: 480px) {
      font-size: 20px;
    }
  }

  .item--sub-title {
    color: #0e0e0e;
    font-weight: 400;
    margin-bottom: 9px;
    padding-left: 75px;
    position: relative;
  }

  .item--sub-title:before {
    content: "";
    width: 50px;
    height: 3px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background-color: #ff0040;
    background-image: -webkit-linear-gradient(left, #ff0040, #ff0040);
    box-shadow: 0 7px 16px rgba(12, 12, 12, 0.22);
  }

  .grid {
    padding: 40px 34px;
    background-color: #fff;
    -webkit-border-radius: 7px;
    -khtml-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
    -webkit-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -khtml-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -moz-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -ms-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -o-box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    box-shadow: 0 0 20px rgba(12, 12, 12, 0.14);
    -webkit-transition: all 300ms linear 0ms;
    -khtml-transition: all 300ms linear 0ms;
    -moz-transition: all 300ms linear 0ms;
    -ms-transition: all 300ms linear 0ms;
    -o-transition: all 300ms linear 0ms;
    transition: all 300ms linear 0ms;
    margin-bottom: 60px;

    &:hover {
      box-shadow: 0 20px 20px rgba(12, 12, 12, 0.14);
    }

    h5 {
      font-size: 20px;
      margin-bottom: 8px;
      font-weight: bold;
      font-family: "Roboto", Sans-serif !important;
      margin-bottom: 16px;
    }

    img {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      border: 1px solid #ccc;
      margin-right: 14px;
      padding: 5px;
    }

    i {
      padding-right: 10px;
      color: #0069c7;
    }

    button {
      box-shadow: 0 7px 16px rgba(12, 12, 12, 0.22);
      background-color: #0069c7 !important;
      font-size: 16px;
      line-height: 55px;
      padding: 0 42px;
      z-index: 1;
      overflow: hidden;
      color: white;
      border-radius: 12px;
      border: none;

      &:hover {
        background-color: red !important;
      }
    }

    .description {
      line-height: 32px;
      padding: 30px 0;
    }
  }
`;
