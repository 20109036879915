import React, { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const Footer: FunctionComponent = () => {
  const logoImage = require("../../../assets/images/logo.svg");

  return (
    <div className="footer-area-wrapper bg-black">
      <div className="footer-area section-space--ptb_80">
        <div className="container">
          <div className="row footer-widget-wrapper">
            <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
              <div className="footer-widget__logo mb-30">
                <img src={logoImage} alt="" />
              </div>
              <ul className="footer-widget__list">
                <li className="text-white">
                  500 Southborough Drive, LL2, South Portland, ME 04106
                </li>
                <li>
                  <a href="/" className="hover-style-link text-white">
                    info@goldensoftsol.com
                  </a>
                </li>
                <li>
                  <a
                    href="/"
                    className="hover-style-link text-white font-weight--bold"
                  >
                    +1(469)213-1949
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <h6 className="footer-widget__title mb-20 text-white">
                IT Services
              </h6>
              <ul className="footer-widget__list">
                <li>
                  <Link
                    to="/DataEngineering"
                    className="hover-style-link text-white"
                  >
                    <span>Data Engineering</span>
                  </Link>{" "}
                </li>
                <li>
                  <Link
                    to="/DataScience"
                    className="hover-style-link text-white"
                  >
                    <span>Data Science</span>
                  </Link>
                </li>{" "}
                <li>
                  <Link
                    to="/MachineLearning"
                    className="hover-style-link text-white"
                  >
                    <span>Machine Learning Services</span>
                  </Link>
                </li>{" "}
                <li>
                  <Link
                    to="/AnalyticServices"
                    className="hover-style-link text-white"
                  >
                    <span>Analytics Services</span>
                  </Link>
                </li>{" "}
                <li>
                  <Link
                    to="/StrategyServices"
                    className="hover-style-link text-white"
                  >
                    <span>Strategy Services</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <h6 className="footer-widget__title mb-20 text-white">
                Quick links
              </h6>
              <ul className="footer-widget__list">
                <li>
                  <a href="/" className="hover-style-link text-white">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <Link to="/ReachUs" className="hover-style-link text-white">
                    Where to Find Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <h6 className="footer-widget__title mb-20 text-white">Support</h6>
              <ul className="footer-widget__list">
                <li>
                  <a href="/" className="hover-style-link text-white">
                    Help &amp; FAQ
                  </a>
                </li>
                <li>
                  <a href="/ReachUs" className="hover-style-link text-white">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
              <div className="footer-widget__title section-space--mb_50" />
              <ul className="footer-widget__list">
                <li>
                  <a href="/" className="image_btn">
                    <img
                      className="img-fluid"
                      src="assets/images/icons/aeroland-button-google-play.webp"
                      alt=""
                    />
                  </a>
                </li>
                <li>
                  <a href="/" className="image_btn">
                    <img
                      className="img-fluid"
                      src="assets/images/icons/aeroland-button-app-store.webp"
                      alt=""
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright-area section-space--pb_30">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 text-center text-md-start ">
              <span className="copyright-text text-white">
                © 2016 Golden Software Solution. All Rights Reserved.{" "}
                <a href="www.newdevex.com">Developed by NEWDEVEX </a>
              </span>
            </div>
            <div className="col-md-6 text-center text-md-end">
              <ul className="list ht-social-networks solid-rounded-icon">
                <li className="item">
                  <a
                    href="https://www.facebook.com/profile.php?id=100093603274102"
                    target="_blank"
                    aria-label="Facebook"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-facebook-f link-icon" />
                  </a>
                </li>
                <li className="item">
                  <a
                    href="https://www.instagram.com/goldensoftwaresolutions/"
                    target="_blank"
                    aria-label="Instagram"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-instagram link-icon" />
                  </a>
                </li>
                <li className="item">
                  <a
                    href="https://www.linkedin.com/company/goldensoftwaresolutions/"
                    target="_blank"
                    aria-label="Linkedin"
                    className="social-link hint--bounce hint--top hint--primary"
                  >
                    <i className="fab fa-linkedin link-icon" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
