import React from "react";
import { Link } from "react-router-dom";
import { GridWhite } from "../../styles";
import HowWeWorks from "../../GenericComponents/HowWeWorks";

export default function MachineLearning() {
  const Img12 = require("../../../assets/images/12.png");
  const Img35 = require("../../../assets/images/35.png");
  const Img36 = require("../../../assets/images/36.png");
  const Img37 = require("../../../assets/images/37.png");
  const Img38 = require("../../../assets/images/38.png");

  const data = [
    {
      title: "Supervised learning",
      description:
        "Supervised learning trains algorithms with labeled datasets to classify or predict accurately, solving real-world problems using methods like neural networks and logistic regression.",
      linkTo: "/Bigdata",
    },
    {
      title: "Unsupervised learning",
      description:
        "Unsupervised learning discovers hidden patterns or groupings in unlabeled datasets without human intervention. It's ideal for exploratory data analysis and feature reduction.",
      linkTo: "/",
    },
    {
      title: "Semi-supervised learning",
      description:
        "Semi-supervised learning combines smaller labeled datasets with larger unlabeled datasets to guide classification and feature extraction when labeled data is scarce or costly.",
      linkTo: "/",
    },
  ];
  return (
    <div>
      <div>
        {/*===========  feature-icon-wrapper  Start =============*/}
        <div className="feature-icon-wrapper bg-gray-2 section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  <h3>
                    Services We Provide{" "}
                    <span className="text-color-primary">
                      {" "}
                      Truly Prominent IT Solutions.
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="feature-list__two">
                  <div className="row">
                    {data.map((item: any) => {
                      return (
                        <div className="col-lg-4 col-md-6 wow move-up mb-5">
                          <GridWhite className="ht-box-icon style-02 single-svg-icon-box">
                            <div className="icon-box-wrap">
                              <div className="content ps-5">
                                <h5 className="heading">{item.title}</h5>
                                <div className="text">{item.description}</div>
                              </div>
                            </div>
                          </GridWhite>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===========  feature-icon-wrapper  End =============*/}

        {/*=========== Service Projects Wrapper Start =============*/}
        <div className="service-projects-wrapper section-space--pt_100 mb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  {/* <h6 className="section-sub-title mb-20">Case studies</h6> */}
                  <h3 className="heading">
                    Applications of Machine Learning in the{" "}
                    <span className="text-color-primary">Real World.</span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        <h3>
                          <p className="sub-text text-color-primary">
                            Fraud detection
                          </p>
                        </h3>
                        <div className="text">
                          Machine learning is being used by banks and other
                          financial institutions to detect fraudulent
                          transactions.By using information about fraudulent
                          transactions that are already known, supervised
                          learning can train a model to identify similar
                          transactions and detect fraud in real time.Anomaly
                          detection can also identify transactions that are
                          atypical and require further investigation.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img35} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img36} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        <h3>
                          <p className="sub-text text-color-primary">
                            Computer vision
                          </p>
                        </h3>
                        <div className="text">
                          This technology enables computers to extract valuable
                          information from visual inputs like digital images and
                          videos. Computer vision, which utilizes convolutional
                          neural networks, has a wide range of practical uses,
                          including tagging photos on social media, analyzing
                          radiology images in healthcare, and enabling
                          self-driving cars in the automotive industry.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        <h3>
                          <p className="sub-text text-color-primary">
                            Customer service
                          </p>
                        </h3>
                        <div className="text">
                          Online chatbots are revolutionizing customer
                          engagement by replacing human agents across various
                          touchpoints. These bots answer FAQs, provide
                          personalized advice, cross-sell products, and suggest
                          sizes for users. Chatbots are deployed on e-commerce
                          sites, messaging apps like Slack and Facebook
                          Messenger, and virtual assistants.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img37} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img38} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Speech recognition
                          </p>
                        </h3>

                        <div className="text">
                          Also called automatic speech recognition (ASR) or
                          speech-to-text, this technology uses natural language
                          processing (NLP) to convert spoken language into a
                          written format. Speech recognition is widely used in
                          mobile devices, such as Siri, to enable voice search
                          and improve accessibility for texting.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=========== Service Projects Wrapper End =============*/}
      </div>

      {/* <HowWeWorks /> */}
    </div>
  );
}
