import React from "react";
import { Link } from "react-router-dom";
import { GridWhite } from "../../styles";
import HowWeWorks from "../../GenericComponents/HowWeWorks";

export default function DataScience() {
  const Img12 = require("../../../assets/images/12.png");
  const Img28 = require("../../../assets/images/28.png");
  const Img29 = require("../../../assets/images/29.png");
  const Img30 = require("../../../assets/images/30.png");
  const Img31 = require("../../../assets/images/31.png");

  const data = [
    {
      title: "Machine Learning",
      description:
        "Machine Learning is the foundation of data science, and requires knowledge of both Machine Learning and statistics.",
      linkTo: "/Bigdata",
    },
    {
      title: "Modeling",
      description:
        "Modeling involves creating mathematical models, selecting appropriate algorithms, and training models.",
      linkTo: "/",
    },

    {
      title: "Programming",
      description:
        "   Programming skills, particularly in Python or R, are needed to carry out successful data science projects.",
      linkTo: "/",
    },
    {
      title: "Databases",
      description:
        "Understanding databases and how to extract data from them is essential for data scientists.",
      linkTo: "/",
    },
    {
      title: "Statistics",
      description:
        "An in-depth knowledge of statistics is fundamental to data science. It enables to obtain more meaningful insights from the data.",
      linkTo: "/",
    },
  ];
  return (
    <div>
      <div>
        {/*===========  feature-icon-wrapper  Start =============*/}
        <div className="feature-icon-wrapper bg-gray section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  {/* <h6 className="section-sub-title mb-20">
                    Industries we Serve
                  </h6> */}
                  <h3 className="heading">
                    Data Science
                    <span className="text-color-primary">
                      {" "}
                      Services We Deliver?
                    </span>
                  </h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <div className="feature-list__two">
                  <div className="row">
                    {data.map((item: any) => {
                      return (
                        <div className="col-lg-4 col-md-6 wow move-up mb-5">
                          <GridWhite className="ht-box-icon style-02 single-svg-icon-box">
                            <div className="icon-box-wrap">
                              <div className="content ms-5">
                                <h5 className="heading">{item.title}</h5>
                                <div className="text">{item.description}</div>
                              </div>
                            </div>
                          </GridWhite>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===========  feature-icon-wrapper  End =============*/}

        {/*=========== Service Projects Wrapper Start =============*/}
        <div className="service-projects-wrapper section-space--pt_100 mb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  {/* <h6 className="section-sub-title mb-20">Case studies</h6> */}
                  <h3 className="heading ">
                    An essential prerequisite for Data Science is to have
                    knowledge of{" "}
                    <span className="text-color-primary">
                      Golden Software Solution Inc.
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img30} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            IT managers
                          </p>
                        </h3>
                        <div className="text">
                          The responsibility of IT managers is to create the
                          infrastructure and architecture required to facilitate
                          data science activities. They ensure that data science
                          teams are appropriately monitored and resourced for
                          optimal efficiency and safety. Additionally, they
                          create and maintain IT environments for data science
                          teams.
                          {/* If an IT manager has been with an organization
                          for a long time, they will have more significant
                          responsibilities. Their role is to ensure that data
                          science activities are aligned with the company's
                          overall IT strategy and goals. */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Data science managers
                          </p>
                        </h3>
                        <div className="text">
                          Data science managers are tasked with overseeing the
                          operations and processes of all data science team
                          members. They manage the day-to-day activities of the
                          three data science teams and are responsible for team
                          building and project planning and monitoring.
                          {/* Data
                          science managers are essential for ensuring that data
                          science activities are aligned with the company's
                          strategic objectives and that data science teams work
                          efficiently and effectively. */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img31} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100 ">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Business Managers
                          </p>
                        </h3>
                        <div className="text">
                          The data science training process is overseen by
                          business managers who work closely with the data
                          science team to define the problem and establish an
                          analytical approach. |
                          {/* Data scientists may report to
                          executives in charge of specific departments, such as
                          marketing, finance, or sales, to ensure timely project
                          completion. The primary responsibility of business
                          managers is to collaborate with data scientists and IT
                          managers to ensure that projects are completed on time
                          and in accordance with established methodologies */}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img29} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=========== Service Projects Wrapper End =============*/}
      </div>

      {/* <HowWeWorks /> */}
    </div>
  );
}
