import React from "react";
import { Link } from "react-router-dom";

export default function SoftwareTechnology() {
  const img11 = require("../../../assets/images/11.png");

  return (
    <div className="site-wrapper-reveal">
      {/*===========  feature-large-images-wrapper  Start =============*/}
      <div className="feature-large-images-wrapper section-space--ptb_100 bg-gray-2">
        <div className="container">
          <div className="cybersecurity-about-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="conact-us-wrap-one managed-it">
                  <h2 className="heading ">
                    Golden Software Solution specializes in{" "}
                    <span className="text-color-primary">
                      {" "}
                      technological and IT-related services
                    </span>{" "}
                    such as product engineering, warranty management, building
                    cloud, infrastructure, network, etc.{" "}
                  </h2>
                  <div className="sub-heading">
                    We’re available for 8 hours a day!
                    <br />
                    Contact to require a detailed analysis and assessment of
                    your plan.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-large-images-wrapper  End =============*/}

      <div className="claim-to-excel-area section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_60">
                <h2 className="heading">
                  Golden Software Solution Consulting Services for Information
                  Technology
                </h2>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="claim-grid-group">
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-one">
                    <div className="icon">
                      <span className="icon-basic-anticlockwise" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">01</h6> */}
                      <h5 className="heading">
                        {" "}
                        Consulting for digital transformation
                      </h5>
                      <p className="text">
                        To enhance the efficiency of your business by devising,
                        prioritizing and leading your digital transformation
                        initiatives.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-two">
                    <div className="icon">
                      <span className="icon-basic-life-buoy" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">02</h6> */}
                      <h5 className="heading">Consulting for IT strategy</h5>
                      <p className="text">
                        To synchronize your IT resources with current and
                        expected business objectives.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-three">
                    <div className="icon">
                      <span className="icon-basic-world" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">03</h6> */}
                      <h5 className="heading"> IT infrastructure assessment</h5>
                      <p className="text">
                        To identify areas for enhancing the effectiveness,
                        safety, conformity, and decreasing the expenses of your
                        IT framework.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-four">
                    <div className="icon">
                      <span className="icon-basic-case" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">04</h6> */}
                      <h5 className="heading">Consulting for IT operations</h5>
                      <p className="text">
                        To establish resilient IT capabilities capable of
                        supporting business strategies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-five">
                    <div className="icon">
                      <span className="icon-basic-lock" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">05</h6> */}
                      <h5 className="heading">
                        IT project and program coordination
                      </h5>
                      <p className="text">
                        To manage activities across one or multiple IT projects
                        to achieve quicker project progress and superior
                        outcomes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row--10">
            <div className="col-lg-12">
              <div className="success-stories-wrap mt-40">
                <div className="modern-number-01 me-5">
                  <h2 className="heading">
                    <span className="mark-text">5+</span>
                  </h2>
                  <p>YEARS IN THE FIELD</p>
                </div>
                <div className="content">
                  <h4 className="heading mb-20">
                    Read further to <br /> our Success <br /> Stories
                  </h4>
                  <a href="#" className="ht-btn ht-btn-md">
                    <i className="far fa-comment-alt me-2" /> Let's talk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="about-banner-wrap"
        style={{
          height: "30rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "rgb(6 10 52)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="about-banner-content text-center section-space--ptb_100">
                <h1 className="mb-15 text-white">IT Support And Services</h1>
                <h5 className="font-weight--normal text-white">
                  Contact the most dependable IT services in the world.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-icon-wrapper  Start =============*/}
      <div className="feature-icon-wrapper section-space--pt_100 section-space--pb_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-wrap text-center section-space--mb_20">
                <h3 className="heading">Prominent IT Support and Services</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="feature-list__three">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="far fa-life-ring" />
                            </div>
                            <h5 className="heading"> Innovative-driven </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              With a deep understanding of time-proven
                              programming technologies, we also keep up with the
                              latest trends and advancements in the industry.
                              Our IT strategies are designed with a focus on
                              intelligent connected devices and ecosystems,
                              decentralized ledgers, P2P transactions, and smart
                              automation using artificial intelligence.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-lock" />
                            </div>
                            <h5 className="heading">Unmatched Experience</h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Our extensive network of IT consultants, solution
                              architects, tech teams, data and security
                              engineers, make the journey from problem
                              identification to solution implementation
                              effortless. We are committed to being there for
                              our clients throughout the entire process,
                              ensuring that they don't give up halfway.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-globe" />
                            </div>
                            <h5 className="heading">
                              Proud of our success stories
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Our portfolio boasts of successful advisory,
                              software implementation, and digital
                              transformation projects, with expertise in
                              managing regulatory and compliance requirements.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-medal" />
                            </div>
                            <h5 className="heading">
                              Service Quality and Security Focused
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Service Quality and Security Focused Our approach
                              is rooted in quality, with a commitment to
                              implementing layered security controls to protect
                              our clients' IT infrastructure and business data.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-icon-wrapper  End =============*/}
    </div>
  );
}
