import React from "react";
import { Link } from "react-router-dom";

export default function BusinessSolutions() {
  const img11 = require("../../../assets/images/11.png");

  return (
    <div className="site-wrapper-reveal">
      <div
        className="about-banner-wrap"
        style={{
          height: "30rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "rgb(6 10 52)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="about-banner-content text-center section-space--ptb_100">
                <h1 className="mb-15 text-white">Business IT Services</h1>
                <h5 className="font-weight--normal text-white">
                  Contact the most dependable IT services in the world.
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="claim-to-excel-area section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_60">
                {/* <h2 className="heading">How we claim to excel?</h2> */}
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="claim-grid-group">
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-one">
                    <div className="icon">
                      <span className="icon-basic-anticlockwise" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">01</h6> */}
                      <h5 className="heading">Cloud Services</h5>
                      <p className="text">
                        Cloud services offer scalability, ease of use, and
                        immediate access to company resources, requiring only a
                        reliable internet connection.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-two">
                    <div className="icon">
                      <span className="icon-basic-life-buoy" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">02</h6> */}
                      <h5 className="heading">Network Security:</h5>
                      <p className="text">
                        Network security measures, such as firewalls, anti-virus
                        software, web filtering, patch management, VPNs, and
                        check-ups from IT service providers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-three">
                    <div className="icon">
                      <span className="icon-basic-world" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">03</h6> */}
                      <h5 className="heading">Computer Training</h5>
                      <p className="text">
                        IT service providers can offer computer training
                        sessions for employees to overcome the hurdle of keeping
                        software and operating systems up-to-date.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-four">
                    <div className="icon">
                      <span className="icon-basic-case" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">04</h6> */}
                      <h5 className="heading">IT Consulting </h5>
                      <p className="text">
                        IT service providers can be hired for consulting
                        services to help tackle large-scale IT projects such as
                        hardware and infrastructure overhauls or business-wide
                        software and operating system updates.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-five">
                    <div className="icon">
                      <span className="icon-basic-lock" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">05</h6> */}
                      <h5 className="heading">Help Desk Support</h5>
                      <p className="text">
                        Help desk support is an important tool for businesses
                        without an in-house IT team, providing users with
                        troubleshooting help for various IT-related issues.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row--10">
            <div className="col-lg-12">
              <div className="success-stories-wrap mt-40">
                <div className="modern-number-01 me-5">
                  <h2 className="heading">
                    <span className="mark-text">5+</span>
                  </h2>
                  <p>YEARS IN THE FIELD</p>
                </div>
                <div className="content">
                  <h4 className="heading mb-20">
                    Read further to <br /> our Success <br /> Stories
                  </h4>
                  <a href="#" className="ht-btn ht-btn-md">
                    <i className="far fa-comment-alt me-2" /> Let's talk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*===========  feature-large-images-wrapper  Start =============*/}
      <div className="feature-large-images-wrapper section-space--ptb_100 bg-gray-2">
        <div className="container">
          <div className="cybersecurity-about-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="conact-us-wrap-one managed-it">
                  <h2 className="heading ">
                    Golden Software Solution specializes in{" "}
                    <span className="text-color-primary">
                      {" "}
                      technological and IT-related services
                    </span>{" "}
                    such as product engineering, warranty management, building
                    cloud, infrastructure, network, etc.{" "}
                  </h2>
                  <div className="sub-heading">
                    We’re available for 8 hours a day!
                    <br />
                    Contact to require a detailed analysis and assessment of
                    your plan.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-large-images-wrapper  End =============*/}

      {/*===========  feature-icon-wrapper  Start =============*/}
      <div className="feature-icon-wrapper section-space--pt_100 section-space--pb_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-wrap text-center section-space--mb_20">
                <h3 className="heading">IT Security Services of Note</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="feature-list__three">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="far fa-life-ring" />
                            </div>
                            <h5 className="heading">
                              Golden Software Solution Management Systems
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              We provide services that improve customer
                              experience by ensuring secure &amp;efficient
                              end-to-end warranty management.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-lock" />
                            </div>
                            <h5 className="heading">
                              Reliable Multi-function Technology
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Our approach involves regularly testing,
                              repairing, &amp; refining each version of our
                              products to propose improvements &amp; enhance
                              reliability.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-globe" />
                            </div>
                            <h5 className="heading">
                              Highly Professional Staffs
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Our team of experts undergoes periodic &amp;
                              accurate testing to ensure their proficiency in
                              developing &amp; proposing product improvements
                              for reliable &amp; high-quality performance.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-medal" />
                            </div>
                            <h5 className="heading">
                              Infrastructure Integration Technology
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              At Golden Software Solution, we have a holistic
                              and integrated approach towards core modernization
                              to experience technological evolution.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-icon-wrapper  End =============*/}
    </div>
  );
}
