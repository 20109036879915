import React from "react";
import { Link } from "react-router-dom";
import { GridWhite } from "../../styles";
import HowWeWorks from "../../GenericComponents/HowWeWorks";

export default function StrategyServices() {
  const Img12 = require("../../../assets/images/12.png");
  const Img22 = require("../../../assets/images/22.png");
  const Img23 = require("../../../assets/images/23.png");
  const Img24 = require("../../../assets/images/24.png");
  const Img25 = require("../../../assets/images/25.png");
  const Img26 = require("../../../assets/images/26.png");

  const data = [
    {
      title: "Machine Learning",
      description:
        "ML is a subset of artificial intelligence that allows computers to learn from data and improve their performance without being explicitly programmed. By leveraging ML techniques, your company can gain valuable insights from data. ",
      linkTo: "/Bigdata",
    },
    {
      title: "Natural Language Processing",
      description:
        "NLP allows computers to process human language accurately and efficiently, whether it's in voice or text form. By working with NLP specialists, your company can gain valuable insights from language data, including sentiment analysis and more.",
      linkTo: "/",
    },
    // {
    //   title: "Expert Systems",
    //   description:
    //     "Expert systems leverage existing knowledge to solve complex problems, such as recommendation engines and diagnosis tools. AI developers can implement these systems within your company, helping you improve efficiency and outcomes.",
    //   linkTo: "/",
    // },
    // {
    //   title: "AI Design",
    //   description:
    //     "AI design plays a crucial role in improving the user experience of AI projects like chatbots, making them feel more human-like. AI designers can help you create better models and build trust with users, resulting in improved engagement and satisfaction.",
    //   linkTo: "/",
    // },
    // {
    //   title: "Neural Networks:",
    //   description:
    //     "Neural networks are computer systems inspired by the human brain that use interconnected nodes to improve performance. With the help of AI developers, your team can create neural networks and utilize deep learning techniques for better results.",
    //   linkTo: "/",
    // },
    // {
    //   title: "Computer Vision",
    //   description:
    //     "Computer vision is an AI subfield that focuses on helping computers acquire, analyze, and understand digital images or video. It mimics the human visual system and involves creating software to process and comprehend visual data.",
    //   linkTo: "/",
    // },
  ];
  return (
    <div>
      <div
        className="contact-us-section-wrappaer machine-learning-contact-us-bg section-space--ptb_120"
        style={{
          backgroundImage: `url(${Img26})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "rgb(6 10 52)",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="conact-us-wrap-three">
                <h6 className="mb-3 section-sub-title">OUR SOLUTION</h6>
                <h3 className="heading text-white">
                  Add AI to your existing contact center technology.
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        {/*===========  feature-icon-wrapper  Start =============*/}
        <div className="feature-icon-wrapper bg-gray section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  {/* <h6 className="section-sub-title mb-20">
                    Industries we Serve
                  </h6> */}
                  <h3 className="heading">
                    <br /> Artificial Intelligence{" "}
                    <span className="text-color-primary">
                      {" "}
                      Development Services
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="feature-list__two">
                  <div className="row">
                    {data.map((item: any) => {
                      return (
                        <div className="col-lg-6 col-md-6 wow move-up mb-5">
                          <GridWhite className="ht-box-icon style-02 single-svg-icon-box">
                            <div className="icon-box-wrap">
                              <div className="icon">
                                <div
                                  className="svg-icon"
                                  id="svg-1"
                                  data-svg-icon="assets/images/svg/linea-basic-heart.svg"
                                />
                              </div>
                              <div className="content">
                                <h5 className="heading">{item.title}</h5>
                                <div className="text">{item.description}</div>
                                <div className="feature-btn">
                                  <Link to={item.linkTo}>
                                    <span className="button-text">Dive in</span>
                                    <i className="fas fa-arrow-right ms-3" />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </GridWhite>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="feature-list-button-box mt-30 text-center">
                  <a href="#" className="ht-btn ht-btn-md">
                    Talk to a consultant
                  </a>
                  <a href="#" className="ht-btn ht-btn-md ht-btn--outline">
                    Contact us now{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===========  feature-icon-wrapper  End =============*/}

        {/*=========== Service Projects Wrapper Start =============*/}
        <div className="service-projects-wrapper section-space--pt_100 mb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  {/* <h6 className="section-sub-title mb-20">Case studies</h6> */}
                  <h3 className="heading">
                    We assemble custom teams from our global talent network of
                    experts <br />
                    <span className="text-color-primary ">
                      to fit your business needs.
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="row align-items-center d-flex">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Product Owners
                          </p>
                        </h3>
                        <div className="text">
                          Our product ownership team includes digital product
                          managers and scrum product owners who have expertise
                          in various industries such as banking, healthcare, and
                          ecommerce.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img22} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row align-items-center d-flex">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img23} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Developers
                          </p>
                        </h3>
                        <div className="text">
                          Our developers are senior software engineers, coders,
                          and architects with extensive experience across a
                          broad range of technologies.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <div className="row align-items-center d-flex">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Business Consultants
                          </p>
                        </h3>
                        <div className="text">
                          Our business consulting team comprises top-tier
                          consultants, strategy and operations specialists, who
                          are well-versed in working with businesses of all
                          sizes and types.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img24} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img25} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Quality Assurance Experts
                          </p>
                        </h3>
                        <div className="text">
                          Our team of quality assurance experts includes
                          seasoned engineers who have skills in various quality
                          assurance techniques, such as manual and automated
                          testing.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=========== Service Projects Wrapper End =============*/}
      </div>
    </div>
  );
}
