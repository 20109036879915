import React from "react";
import { Link } from "react-router-dom";
import { GridWhite } from "../../styles";
import HowWeWorks from "../../GenericComponents/HowWeWorks";

export default function DataEngineering() {
  const Img21 = require("../../../assets/images/21.png");
  const Img32 = require("../../../assets/images/32.png");
  const Img33 = require("../../../assets/images/33.png");
  const Img34 = require("../../../assets/images/34.png");

  const data = [
    {
      title: "Data acquisition",
      description:
        "Data is extracted from diverse sources such as SQL and NoSQL databases, IoT devices, websites, and streaming services and transformed for analysis. Data can be either structured or unstructured.",
      linkTo: "/DataEngineering",
    },
    {
      title: "Data transformation",
      description:
        "Preparing data for analysis involves data transformation, which includes removing errors and duplicates, normalization, and conversion to required formats via automated processes and APIs.",
      linkTo: "/DataEngineering",
    },
    {
      title: "Data Serving",
      description:
        "Data serving involves querying and accessing the transformed data through APIs or SQL queries. It can also involve providing real-time access to data through streaming technologies.",
      linkTo: "/DataEngineering",
    },
  ];
  return (
    <div>
      <div>
        {/*===========  feature-icon-wrapper  Start =============*/}
        <div className="feature-icon-wrapper bg-gray-2 section-space--ptb_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_40">
                  {/* <h6 className="section-sub-title mb-20">
                    Industries we Serve
                  </h6> */}
                  <h3>
                    Services We Provide{" "}
                    <span className="text-color-primary">
                      {" "}
                      Truly Prominent IT Solutions.
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="feature-list__two">
                  <div className="row">
                    {data.map((item: any) => {
                      return (
                        <div className="col-lg-6 col-md-6 wow move-up mb-5">
                          <GridWhite className="ht-box-icon style-02 single-svg-icon-box">
                            <div className="icon-box-wrap">
                              <div className="content ps-5">
                                <h3>{item.title}</h3>
                                <div className="text">{item.description}</div>
                                {/* <div className="feature-btn">
                                  <Link to={item.linkTo}>
                                    <span className="button-text">Dive in</span>
                                    <i className="fas fa-arrow-right ms-3" />
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                          </GridWhite>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*===========  feature-icon-wrapper  End =============*/}

        {/*=========== Service Projects Wrapper Start =============*/}
        <div className="service-projects-wrapper section-space--pt_100 mb-20">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  {/* <h6 className="section-sub-title mb-20">Some recommendations for acing data engineer case interviews are:</h6> */}
                  <h3 className="text-capitilize">
                    Some Tips for Succeeding in{" "}
                    <span className="text-color-primary">
                      Data Engineer Case Interviews?
                    </span>
                  </h3>
                </div>
              </div>
            </div>
            <div className="swiper-container">
              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}
                        <h3>
                          <p className="sub-text text-color-primary">
                            Analytical Thinking
                          </p>
                        </h3>
                        <div className="text">
                          Employing a logical and methodical approach to
                          problem-solving, carefully breaking down complex
                          issues into smaller parts to derive effective
                          solutions.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img32} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row d-flex align-items-center">
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-1">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img33} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-2">
                      <div className="service-project-slide-info">
                        {/* <h4 className="heading font-weight--reguler mb-10">
                          Aqua Technology Case Studies
                        </h4> */}

                        <h3>
                          <p className="sub-text text-color-primary">
                            Effective communication skills
                          </p>
                        </h3>
                        <div className="text">
                          Clearly explain your decision-making process and
                          reasoning to the interviewer.
                        </div>
                        <div className="text mt-3">
                          Understand various design patterns and technologies
                          that can be used to solve architecture problems.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="swiper-wrapper section-space--pt_100 section-space--pb_100">
                <div className="swiper-slide">
                  <div className="row">
                    <div className="slide-content col-lg-6 col-xl-5 order-2 order-lg-1">
                      <div className="service-project-slide-info">
                        <h3>
                          <p className="sub-text text-color-primary">
                            Anticipatory Thinking
                          </p>
                        </h3>
                        <div className="text">
                          Successful data engineers are able to consider
                          potential future needs and design solutions that can
                          accommodate them. Interviewers want to see that you
                          can assess a solution in terms of its scalability and
                          adaptability to future changes.
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-xl-7 order-1 order-lg-2">
                      <div className="slide-image">
                        <div className="image-wrap">
                          <div className="image">
                            <img className="img-fluid" src={Img34} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*=========== Service Projects Wrapper End =============*/}
      </div>
    </div>
  );
}
