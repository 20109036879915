import React, { FunctionComponent, useState } from "react";
import Router from "../../routes";
import { Link } from "react-router-dom";
import { SideMenu } from "./styles";

const Header: FunctionComponent = () => {
  const logoImage = require("../../../assets/images/logo.svg");
  const [isMobileMenu, setIsMobileMenu] = useState(false);

  const onMenu = () => {
    setIsMobileMenu(!isMobileMenu);
  };

  const onMenuClose = () => {
    setIsMobileMenu(false);
  };

  return (
    <>
      {" "}
      <div className="header-area header-area--default">
        {/* Header Bottom Wrap Start */}
        <div className="header-bottom-wrap header-sticky">
          <div className="container">
            <div className="row m-0">
              <div className="col-lg-12 px-0">
                <div className="header default-menu-style position-relative">
                  {/* brand logo */}
                  <div className="header__logo">
                    <Link to="/home">
                      <img src={logoImage} alt="" />
                    </Link>
                  </div>
                  {/* header midle box  */}
                  <div className="header-midle-box justify-content-md-end">
                    <div className="header-bottom-wrap d-md-block d-none">
                      <div className="header-bottom-inner">
                        <div className="header-bottom-left-wrap">
                          {/* navigation menu */}
                          <div className="header__navigation d-none d-xl-block">
                            <nav className="navigation-menu primary--menu">
                              <ul className="mb-0">
                                {" "}
                                <li className="has-children has-children--multilevel-submenu">
                                  <a>
                                    <span>Services</span>
                                  </a>
                                  <ul className="submenu">
                                    <li>
                                      <Link to="/StrategyServices">
                                        <span>AI Services</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="/DataEngineering">
                                        <span>Data Engineering</span>
                                      </Link>{" "}
                                    </li>
                                    <li>
                                      <Link to="/DataScience">
                                        <span>Data Science</span>
                                      </Link>
                                    </li>{" "}
                                    <li>
                                      <Link to="/MachineLearning">
                                        <span>Machine Learning Services</span>
                                      </Link>
                                    </li>{" "}
                                    {/* <li>
                                      <Link to="/AnalyticServices">
                                        <span>Analytics Services</span>
                                      </Link>
                                    </li>{" "} */}
                                  </ul>
                                </li>
                                <li className="has-children has-children--multilevel-submenu">
                                  <a>
                                    <span>About Us</span>
                                  </a>
                                  <ul className="submenu">
                                    <li>
                                      <Link to="/AboutUs">
                                        <span>About Us</span>
                                      </Link>{" "}
                                    </li>
                                    <li>
                                      <Link to="/Careers">
                                        <span>Careers</span>
                                      </Link>
                                    </li>{" "}
                                    {/* <li>
                                      <Link to="/MachineLearning">
                                        <span>Partnership</span>
                                      </Link>
                                    </li>{" "} */}
                                  </ul>
                                </li>
                                <li className="has-children has-children--multilevel-submenu">
                                  <a>
                                    <span>We Serve</span>
                                  </a>
                                  <ul className="submenu">
                                    <li>
                                      <Link to="/Healthcare">
                                        <span>Industry Expertise</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link to="SoftwareTechnology">
                                        <span>IT Support And Services</span>
                                      </Link>
                                    </li>

                                    <li>
                                      <Link to="/BusinessSolutions">
                                        <span>Business IT Services</span>
                                      </Link>
                                    </li>
                                  </ul>
                                </li>
                                <li className="has-children has-children--multilevel-submenu">
                                  <a>
                                    <span>What We Do</span>
                                  </a>
                                  <ul className="submenu">
                                    <li>
                                      <Link to="/Bigdata">
                                        <span>Bigdata</span>
                                      </Link>
                                    </li>
                                    {/* <li>
                                      <Link to="/SoftwareDevelopment">
                                        <span>Software Development</span>
                                      </Link>
                                    </li> */}
                                  </ul>
                                </li>
                                <li>
                                  <Link to="/ReachUs">Reach us</Link>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* header right box */}
                  <div className="header-right-box d-md-none">
                    {/* mobile menu */}
                    <div
                      className="mobile-navigation-icon d-block d-xl-none"
                      // id="mobile-menu-trigger"
                      onClick={onMenu}
                    >
                      <i />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header Bottom Wrap End */}
      </div>
      {isMobileMenu && (
        <SideMenu>
          <div>
            <div className="">
              <div className="mobile-menu-overlay__body">
                <nav className="offcanvas-navigation">
                  <ul>
                    <li className="has-children">
                      <a href="/" onClick={onMenuClose}>
                        Services
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/DataEngineering" onClick={onMenuClose}>
                            <span>Data Engineering</span>
                          </Link>{" "}
                        </li>
                        <li>
                          <Link to="/DataScience" onClick={onMenuClose}>
                            <span>Data Science</span>
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/MachineLearning" onClick={onMenuClose}>
                            <span>Machine Learning Services</span>
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/AnalyticServices" onClick={onMenuClose}>
                            <span>Analytics Services</span>
                          </Link>
                        </li>{" "}
                        <li>
                          <Link to="/StrategyServices" onClick={onMenuClose}>
                            <span>Strategy Services</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="/">About Us</a>
                      <ul className="sub-menu" onClick={onMenuClose}>
                        <li>
                          <Link to="/AboutUs" onClick={onMenuClose}>
                            <span>About Us</span>
                          </Link>{" "}
                        </li>
                        <li>
                          <Link to="/DataScience" onClick={onMenuClose}>
                            <span>Careers</span>
                          </Link>
                        </li>{" "}
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="/" onClick={onMenuClose}>
                        Specilization
                      </a>
                      <ul className="sub-menu">
                        <li>
                          <Link to="/Bigdata" onClick={onMenuClose}>
                            <span>Bigdata</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li className="has-children">
                      <a href="/" onClick={onMenuClose}>
                        We Serve
                      </a>
                      <ul className="submenu">
                        <li>
                          <Link to="/Healthcare" onClick={onMenuClose}>
                            <span>Healthcare</span>
                          </Link>
                        </li>
                        <li>
                          <Link to="SoftwareTechnology" onClick={onMenuClose}>
                            <span>Software Technology</span>
                          </Link>
                        </li>

                        <li>
                          <Link to="/BusinessSolutions" onClick={onMenuClose}>
                            <span>Business solution</span>
                          </Link>
                        </li>
                      </ul>
                    </li>
                    <li>
                      <Link to="/ReachUs" onClick={onMenuClose}>
                        Reach us
                      </Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </SideMenu>
      )}
      <Router />
    </>
  );
};

export default Header;
