import React from "react";
import { Link } from "react-router-dom";

export default function Healthcare() {
  const img11 = require("../../../assets/images/11.png");

  return (
    <div className="site-wrapper-reveal">
      <div
        className="about-banner-wrap"
        style={{
          height: "30rem",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundColor: "rgb(6 10 52)",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="about-banner-content text-center section-space--ptb_100">
                <h1 className="mb-15 text-white">Industry expertise</h1>
                <h5 className="font-weight--normal text-white">
                  {/* Revamp your business processes to thrive in the digital age. */}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="claim-to-excel-area section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_60">
                <h2 className="heading">
                  Revamp your business processes to thrive in the digital age.
                </h2>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="claim-grid-group">
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-one">
                    <div className="icon">
                      <span className="icon-basic-anticlockwise" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">01</h6> */}
                      <h5 className="heading">
                        {" "}
                        Financial process optimization
                      </h5>
                      <p className="text">
                        Increasing accuracy and efficiency in financial
                        management processes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-two">
                    <div className="icon">
                      <span className="icon-basic-life-buoy" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">02</h6> */}
                      <h5 className="heading">Digital operations management</h5>
                      <p className="text">
                        Managing workflows in a digital environment to increase
                        operational efficiency.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-three">
                    <div className="icon">
                      <span className="icon-basic-world" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">03</h6> */}
                      <h5 className="heading">Enterprise asset management</h5>
                      <p className="text">
                        Improving planning and utilization of enterprise assets
                        for increased efficiency and cost savings.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-four">
                    <div className="icon">
                      <span className="icon-basic-case" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">04</h6> */}
                      <h5 className="heading">Human resources management</h5>
                      <p className="text">
                        Simplifying HR administration and enabling employee
                        self-service.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ht-box-icon style-04">
                  <div className="icon-box-wrap color-five">
                    <div className="icon">
                      <span className="icon-basic-lock" />
                    </div>
                    <div className="content">
                      {/* <h6 className="sub-heading">05</h6> */}
                      <h5 className="heading">
                        {" "}
                        Business analytics and intelligence
                      </h5>
                      <p className="text">
                        Leveraging enterprise data to inform business decisions
                        and improve performance.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row row--10">
            <div className="col-lg-12">
              <div className="success-stories-wrap mt-40">
                <div className="modern-number-01 me-5">
                  <h2 className="heading">
                    <span className="mark-text">5+</span>
                  </h2>
                  <p>YEARS IN THE FIELD</p>
                </div>
                <div className="content">
                  <h4 className="heading mb-20">
                    Read further to <br /> our Success <br /> Stories
                  </h4>
                  <a href="#" className="ht-btn ht-btn-md">
                    <i className="far fa-comment-alt me-2" /> Let's talk
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*===========  feature-large-images-wrapper  Start =============*/}
      <div className="feature-large-images-wrapper section-space--ptb_100 bg-gray-2">
        <div className="container">
          <div className="cybersecurity-about-box">
            <div className="row">
              <div className="col-lg-12">
                <div className="conact-us-wrap-one managed-it">
                  <h2 className="heading ">
                    Golden Software Solution specializes in{" "}
                    <span className="text-color-primary">
                      {" "}
                      technological and IT-related services
                    </span>{" "}
                    such as product engineering, warranty management, building
                    cloud, infrastructure, network, etc.{" "}
                  </h2>
                  <div className="sub-heading">
                    We’re available for 8 hours a day!
                    <br />
                    Contact to require a detailed analysis and assessment of
                    your plan.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-large-images-wrapper  End =============*/}

      {/*===========  feature-icon-wrapper  Start =============*/}
      <div className="feature-icon-wrapper section-space--pt_100 section-space--pb_70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-wrap text-center section-space--mb_20">
                <h3 className="heading">
                  Combining an unquenchable thirst for innovation
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="feature-list__three">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="far fa-life-ring" />
                            </div>
                            <h5 className="heading">
                              {" "}
                              Virtual simulation technology
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Creating immersive digital environments for
                              various purposes including training, education,
                              and entertainment.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-lock" />
                            </div>
                            <h5 className="heading">
                              Augmented reality technology
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Overlaying digital objects onto the real world to
                              enhance user experience and aid in marketing,
                              education, and other areas.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-globe" />
                            </div>
                            <h5 className="heading">Blockchain solutions</h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Enabling secure and transparent management of
                              supply chains, financial transactions, NFT
                              trading, cryptocurrency mining, clinical trials,
                              and more.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="grid-item animate">
                      <div className="ht-box-icon style-03">
                        <div className="icon-box-wrap">
                          <div className="content-header">
                            <div className="icon">
                              <i className="fas fa-medal" />
                            </div>
                            <h5 className="heading">
                              Artificial intelligence applications
                            </h5>
                          </div>
                          <div className="content">
                            <div className="text">
                              Providing personalized experiences, computer
                              vision, speech recognition, predictive analytics,
                              and robotic process automation, among other
                              capabilities, based on contextual information.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*===========  feature-icon-wrapper  End =============*/}
    </div>
  );
}
