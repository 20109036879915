import React from "react";
import { Link } from "react-router-dom";

export default function Bigdata() {
  const Img13 = require("../../../assets/images/13.png");
  const Img17 = require("../../../assets/images/17.png");
  const Img18 = require("../../../assets/images/18.png");
  const Img19 = require("../../../assets/images/19.png");
  const Img20 = require("../../../assets/images/20.png");

  return (
    <div className="site-wrapper-reveal">
      {/*=========== About Company Area Start ==========*/}
      <div className="machine-learning-about-company-area machine-learning-about-bg section-space--ptb_120">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-left section-space--mb_30">
                <h6 className="section-sub-title mb-20">
                  Golden Software Solution
                </h6>
                <h2 className="heading">
                  Big Data can be leveraged by{" "}
                  <span className="text-color-primary">anyone in your</span>{" "}
                  <br />
                  organization to make an{" "}
                  <span className="text-color-primary">impact</span>
                </h2>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="image-inner-video-section">
                <div className="main-video-box video-popup">
                  <a
                    href="https://www.youtube.com/watch?v=9No-FiEInLA"
                    className="video-link  mt-30"
                  >
                    <div className="single-popup-wrap">
                      <img
                        className="img-fluid border-radus-5"
                        src={Img13}
                        alt=""
                      />
                      <div className="ht-popup-video video-button">
                        <div className="video-mark">
                          <div className="wave-pulse wave-pulse-1" />
                          <div className="wave-pulse wave-pulse-2" />
                        </div>
                        <div className="video-button__two">
                          <div className="video-play">
                            <span className="video-play-icon" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
                <div className="machine-learning-mark-text mt-30">
                  A better approach would be to perform installations yourself
                  and guarantee that all data transmitted within the
                  organization is fully encrypted from start to finish.
                </div>
              </div>
            </div>
            <div className="col-lg-5 ms-auto mt-30">
              <div className="machine-learning-about-content">
                <div className="section-title mb-20">
                  <h3>Big Data</h3>
                  <p className="dec-text mt-20">
                    The term "big data" refers to vast amounts of data, both
                    structured and unstructured, that businesses encounter
                    regularly and struggle to manage. However, what really
                    counts is how organizations use this data. By analyzing big
                    data, they can gain valuable insights that inform better
                    decisions and empower strategic business initiatives.
                  </p>
                  {/* <div className="button-box mt-30">
                    <a href="#" className="ht-btn ht-btn-md">
                      Talk to a consultant
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=========== About Company Area End ==========*/}
      {/*=========== Machine Learning Service Area Start ==========*/}
      <div className="machine-learning-service-area section-space--ptb_100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_30">
                <h6 className="section-sub-title mb-20">
                  WHY CHOOSE GOLEN SOFT SOLUTIONS
                </h6>
                <h2 className="heading">
                  Big Data in
                  <br /> for{" "}
                  <span className="text-color-primary">Today’s World</span>
                </h2>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="feature-images__five">
                <div className="row">
                  <div className="col-lg-4 col-md-6 wow move-up">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-05">
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <div className="default-image">
                            <img className="img-fulid" src={Img17} alt="" />
                          </div>
                          <div className="hover-images">
                            <img
                              className="img-fulid"
                              src="assets/images/icons/n-icon-1-white.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="heading">
                            What is the role of a data hero?
                          </h5>
                          <div className="text">
                            Data heroes include data scientists who analyze data
                            for insights, data engineers who create DataOps
                            pipelines, and data officers who ensure data is
                            reliable and managed responsibly. Success in
                            analytics is achieved through synergy among these
                            roles.
                          </div>
                          <div className="box-images-arrow">
                            <a href="#">
                              <span className="button-text">
                                Get to know Golden Software Solution
                              </span>
                              <i className="fas fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 wow move-up">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-05">
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <div className="default-image">
                            <img className="img-fulid" src={Img18} alt="" />
                          </div>
                          <div className="hover-images">
                            <img
                              className="img-fulid"
                              src="assets/images/icons/n-icon-2-white.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="heading">
                            Clarifying the difference between a data lake and a
                            data warehouse
                          </h5>
                          <div className="text">
                            In a discussion about data management, Phil Simon
                            clarifies the distinction between a data lake and a
                            data warehouse. He explains the functions,
                            differences, and situations in which each may be the
                            better choice.
                          </div>
                          <div className="box-images-arrow">
                            <a href="#">
                              <span className="button-text">
                                Get to know Golden Software Solution
                              </span>
                              <i className="fas fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                  <div className="col-lg-4 col-md-6 wow move-up">
                    {/* ht-box-icon Start */}
                    <div className="ht-box-images style-05">
                      <div className="image-box-wrap">
                        <div className="box-image">
                          <div className="default-image">
                            <img className="img-fulid" src={Img19} alt="" />
                          </div>
                          <div className="hover-images">
                            <img
                              className="img-fulid"
                              src="assets/images/icons/n-icon-3-white.png"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="content">
                          <h5 className="heading">
                            The synergy between big data and cloud computing
                          </h5>
                          <div className="text">
                            Cloud computing and big data complement each other,
                            delivering cost-effective, agile, and elastic
                            solutions for demanding big data projects requiring
                            intense processing and storage.
                          </div>
                          <div className="box-images-arrow">
                            <a href="#">
                              <span className="button-text">
                                Get to know Golden Software Solution
                              </span>
                              <i className="fas fa-arrow-right" />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ht-box-icon End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*=========== Machine Learning Service Area End ==========*/}
      {/*====================  Conact us Section Start ====================*/}
      <div className="contact-us-section-wrappaer machine-learning-contact-us-bg section-space--ptb_120">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="conact-us-wrap-three">
                <h6 className="mb-3 section-sub-title">OUR SOLUTION</h6>
                <h3 className="heading text-white">
                  Add AI to your existing contact center technology.
                </h3>
              </div>
              <div className="contact-info-two mt-40 text-left">
                <div className="contact-us-button mt-20">
                  <a href="#" className="btn btn--secondary">
                    Contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  Conact us Section End  ====================*/}

      {/*===========  Projects wrapper Start =============*/}
      <div className="projects-wrapper section-space--ptb_100 bg-gray-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* section-title-wrap Start */}
              <div className="section-title-wrap text-center section-space--mb_40">
                {/* <h6 className="section-sub-title mb-20">Case studies</h6> */}
                <h3 className="heading">Case studies</h3>
              </div>
              {/* section-title-wrap Start */}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="projects-wrap swiper-container projects-slider__container">
                <div className="row">
                  <div className="col-12 col-sm-4">
                    {/* Projects Wrap Start */}
                    <a href="#" className="projects-wrap style-01 wow move-up">
                      <div className="projects-image-box">
                        <div className="projects-image">
                          <img
                            className="img-fluid"
                            src="assets/images/projects/case-study-01-480x298.png"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Big Data Case Studeis</h6>
                          <div className="post-categories">Netflix</div>
                          <div className="text">
                            Netflix utilizes Big Data to power its
                            'recommendation engine' for personalized viewing
                            suggestions, while also positioning itself as a
                            content creator that relies on data analysis of
                            customer watch behavior and ratings. Traditional
                            business intelligence tools such as Hadoop, Hive,
                            and Pig are employed in their data infrastructure.
                          </div>
                          <div className="box-projects-arrow">
                            <span className="button-text">View case study</span>
                            <i className="fas fa-arrow-right ml-1" />
                          </div>
                        </div>
                      </div>
                    </a>
                    {/* Projects Wrap End */}
                  </div>
                  <div className="col-12 col-sm-4">
                    {/* Projects Wrap Start */}
                    <a href="#" className="projects-wrap style-01 wow move-up">
                      <div className="projects-image-box">
                        <div className="projects-image">
                          <img
                            className="img-fluid"
                            src="assets/images/projects/case-study-02-480x298.png"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Big Data Case Studeis</h6>
                          <div className="post-categories">Walmart</div>
                          <div className="text">
                            By utilizing data mining, Walmart improved its
                            customer conversion rate and optimized the shopping
                            experience by providing personalized product
                            recommendations based on purchasing patterns. As the
                            world's largest retailer, Walmart implemented
                            effective data mining and advanced e-commerce
                            technologies.
                          </div>
                          <div className="box-projects-arrow">
                            <span className="button-text">View case study</span>
                            <i className="fas fa-arrow-right ml-1" />
                          </div>
                        </div>
                      </div>
                    </a>
                    {/* Projects Wrap End */}
                  </div>
                  <div className="col-12 col-sm-4">
                    {/* Projects Wrap Start */}
                    <a href="#" className="projects-wrap style-01 wow move-up">
                      <div className="projects-image-box">
                        <div className="projects-image">
                          <img
                            className="img-fluid"
                            src="assets/images/projects/case-study-03-480x298.png"
                            alt=""
                          />
                        </div>
                        <div className="content">
                          <h6 className="heading">Big Data Case Sttudies</h6>
                          <div className="post-categories">eBay</div>
                          <div className="text">
                            eBay faces the challenge of analyzing and acting on
                            streaming data, requiring evolving methods. They use
                            Apache Spark, Storm, Kafka to tag data with
                            metadata, making it consumable while ensuring
                            security and permission levels. eBay leads big data
                            and contributes knowledge to the open-source
                            community.
                          </div>
                          <div className="box-projects-arrow">
                            <span className="button-text">View case study</span>
                            <i className="fas fa-arrow-right ml-1" />
                          </div>
                        </div>
                      </div>
                    </a>
                    {/* Projects Wrap End */}
                  </div>
                </div>
                {/* <div className="swiper-pagination swiper-pagination-project mt_20" /> */}
              </div>
              {/* <div className="section-under-heading text-center section-space--mt_40">
                Stop wasting time and money on technology{" "}
                <a href="#">Let’s get started</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/*===========  Projects wrapper End =============*/}

      {/*============ Contact Us Area Start =================*/}
      <div className="contact-us-area service-contact-bg section-space--ptb_100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-info sytle-one service-contact text-left">
                <div className="contact-list-item">
                  <a href="tel:190068668" className="single-contact-list">
                    <div className="content-wrap">
                      <div className="content">
                        <div className="icon">
                          <span className="fas fa-phone" />
                        </div>
                        <div className="main-content">
                          <h6 className="heading">Call for advice now!</h6>
                          <div className="text">+1(469)213-1949</div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a
                    href="mailto:info@goldensoftsol.com"
                    className="single-contact-list"
                  >
                    <div className="content-wrap">
                      <div className="content">
                        <div className="icon">
                          <span className="far fa-envelope" />
                        </div>
                        <div className="main-content">
                          <h6 className="heading">Say hello</h6>
                          <div className="text">info@goldensoftsol.com</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*============ Contact Us Area End =================*/}
    </div>
  );
}
